import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-lansing-michigan.png'
import image0 from "../../images/cities/scale-model-of-north-lansing-dam-in-lansing-michigan.png"
import image1 from "../../images/cities/scale-model-of-impression-5-science-center-in-lansing-michigan.png"
import image2 from "../../images/cities/scale-model-of-r.e.-olds-transportation-museum-in-lansing-michigan.png"
import image3 from "../../images/cities/scale-model-of-potter-park-zoo-in-lansing-michigan.png"
import image4 from "../../images/cities/scale-model-of-eli-and-edythe-broad-art-museum-at-michigan-state-university-in-lansing-michigan.png"
import image5 from "../../images/cities/scale-model-of-greater-lansing-visitor-center---east-lansing-in-lansing-michigan.png"
import image6 from "../../images/cities/scale-model-of-travel-michigan-in-lansing-michigan.png"
import image7 from "../../images/cities/scale-model-of-visitor-welcome-center-in-lansing-michigan.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Lansing'
            state='Michigan'
            image={image}
            lat='42.7143'
            lon='-84.5593'
            attractions={ [{"name": "North Lansing Dam", "vicinity": "Lansing River Trail, Lansing", "types": ["point_of_interest", "establishment"], "lat": 42.7455934, "lng": -84.54926260000002}, {"name": "Impression 5 Science Center", "vicinity": "200 Museum Dr, Lansing", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.7322917, "lng": -84.54866659999999}, {"name": "R.E. Olds Transportation Museum", "vicinity": "240 Museum Dr, Lansing", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.7311579, "lng": -84.54801809999998}, {"name": "Potter Park Zoo", "vicinity": "1301 S Pennsylvania Ave, Lansing", "types": ["zoo", "point_of_interest", "establishment"], "lat": 42.71786960000001, "lng": -84.52767130000001}, {"name": "Eli and Edythe Broad Art Museum at Michigan State University", "vicinity": "547 E Circle Dr, East Lansing", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.7327501, "lng": -84.476653}, {"name": "Greater Lansing Visitor Center - East Lansing", "vicinity": "549 E Grand River Ave, East Lansing", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 42.7335541, "lng": -84.47698809999997}, {"name": "Travel Michigan", "vicinity": "300 N Washington Square, Lansing", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 42.7361829, "lng": -84.551401}, {"name": "Visitor Welcome Center", "vicinity": "East Lansing", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 42.71978259999999, "lng": -84.4905943}] }
            attractionImages={ {"North Lansing Dam":image0,"Impression 5 Science Center":image1,"R.E. Olds Transportation Museum":image2,"Potter Park Zoo":image3,"Eli and Edythe Broad Art Museum at Michigan State University":image4,"Greater Lansing Visitor Center - East Lansing":image5,"Travel Michigan":image6,"Visitor Welcome Center":image7,} }
       />);
  }
}